@keyframes jump {
  0% {
    transform: translate3d(0, 0, 0);
  }
  25% {
    transform: translate3d(0, 25%, 0);
  }
  50% {
    transform: translate3d(0, 50%, 20%);
  }
  100% {
    transform: translate3d(0, 100%, 0);
  }
}
.jump {
  transform-origin: 50% 50%;
  animation: jump 0.5s linear alternate infinite;
}

@keyframes left {
  0% {
    right: 0%;
  }
  25% {
    right: 25%;
  }
  50% {
    right: 50%;
  }
  100% {
    right: 100%;
  }
}

.ball {
  transform-origin: 50% 50%;
  animation: left 2s linear alternate infinite;
}
